import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import getCookie from '../../../../../../../config/static/js/utils/getCookie';

function FinishSignUp({
  accountType,
  subscriptionId = null,
  invitations,
  guestCheckout = false,
}) {
  // Default behavior is to redirect to the homepage
  const url = new URL(window.location.origin);
  const searchParams = new URLSearchParams(window.location.search);
  const nextParam = searchParams.get('next');

  // If the signup workflow is a guest checkout, redirect to the current Sample Request page
  if (guestCheckout) {
    // Normal account creation flow is embedded in the Sample Request page, so we just keep the current URL
    url.href = window.location.href;
  } else if (nextParam) {
    // Social account creation flow redirects to the sign up page, so we inject the next parameter with the original URL
    url.pathname = nextParam;
    url.searchParams.append('requestSample', true);
  }

  // If the account type is media, add a query parameter to the URL
  // This looks like unused code, we must evaluate if it's necessary
  if (accountType === 'media') url.searchParams.append('pendingMsg', true);

  const [processingStatus, setProcessingStatus] = useState(true);

  const goToUrl = () => {
    window.location = url;
  };

  // Automatically redirect the user to the homepage
  // after 15 seconds
  useEffect(() => {
    setInterval(() => {
      if (!processingStatus) goToUrl();
    }, 15000);
  }, []);

  // Wait 5 seconds before allowing the user
  // to click Start Browsing
  useEffect(() => {
    // No need to check subscription status for media and free tiered brand accounts
    if (
      accountType === 'media' ||
      (accountType === 'brand' && !subscriptionId)
    ) {
      setProcessingStatus(false);
      return;
    }

    const intervalId = setInterval(() => {
      if (processingStatus === false || !subscriptionId) return;
      fetch(`/api/stripe/subscriptions/${subscriptionId}/status/`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': getCookie('csrftoken'),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === 'active' || data.status === 'trialing') {
            setProcessingStatus(false);
            // And also kill the interval
            clearInterval(intervalId);
          }
        });
    }, 5000);
  }, []);

  // Automatically redirect the user to the Sample Request page
  // if in the guest checkout workflow, after 5 seconds
  useEffect(() => {
    if (guestCheckout || nextParam) {
      setTimeout(() => {
        goToUrl();
      }, 5000);
    }
  }, []);

  return accountType !== 'media' && invitations.length < 1 ? (
    <div className="sign-up-finish">
      <p className="sign-up-finish__emoji">🥳</p>
      <h3 className="sign-up-finish__welcome">Welcome to Press Hook!</h3>
      <p className="sign-up-finish__content">
        We're excited to have you <br /> join the community!
      </p>
      <button
        type="button"
        className="multi-step-button multi-step-button--small multi-step-button--flex-center"
        onClick={goToUrl}
        disabled={processingStatus}
      >
        {processingStatus ? 'Provisioning your account...' : 'Start Browsing!'}
      </button>
    </div>
  ) : (
    <div className="sign-up-finish">
      <p className="sign-up-finish__emoji">🥳</p>
      <h3 className="sign-up-finish__welcome">Welcome to Press Hook!</h3>
      <p className="sign-up-finish__content">
        We're excited to have you <br /> join the community!
      </p>
      {invitations.length > 0 ? (
        <button
          type="button"
          className="multi-step-button multi-step-button--small multi-step-button--flex-center"
          onClick={goToUrl}
        >
          Start Browsing
        </button>
      ) : (
        <p className="sign-up-finish__content sign-up-finish__content--detail">
          Please check your email for a confirmation link.
        </p>
      )}
      {guestCheckout ||
        (nextParam && (
          <p className="sign-up-finish__content sign-up-finish__content--detail">
            Redirecting you to the Sample Request page...
          </p>
        ))}
    </div>
  );
}

FinishSignUp.propTypes = {
  accountType: PropTypes.string,
  subscriptionId: PropTypes.string,
  invitations: PropTypes.arrayOf(PropTypes.string).isRequired,
  guestCheckout: PropTypes.bool,
};

export default FinishSignUp;
