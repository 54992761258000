import React from 'react';
import ReactDOM from 'react-dom/client';

import SocialSignInSection from '../SocialSignIn/SocialSignInSection';

/**
 * This file is for injecting the Social Sign In Section into the page.
 */

document.addEventListener('DOMContentLoaded', () => {
  const reactTarget = document.querySelector('#js-social-signin');
  if (reactTarget) {
    const {
      accountType,
      csrfToken,
      guestCheckout,
      mediaProfilePk,
      googleUrl,
      linkedinUrl,
      microsoftUrl,
      shopifyUrl,
      isLogin,
    } = reactTarget.dataset;
    const guestCheckoutBool = guestCheckout !== undefined;

    const root = ReactDOM.createRoot(reactTarget);
    root.render(
      <SocialSignInSection
        accountType={accountType}
        csrfToken={csrfToken}
        guestCheckout={guestCheckoutBool}
        mediaProfilePk={mediaProfilePk}
        googleSignInURL={googleUrl}
        linkedinSignInURL={linkedinUrl}
        microsoftSignInURL={microsoftUrl}
        shopifySignInURL={shopifyUrl}
        isLogin={isLogin}
      />
    );
  }
});
