import React from 'react';
import SocialSignInButton from './SocialSignInButton';
import ShopifySignInForm from './ShopifySignInForm';
import { Collapse, Button, Grid, ThemeProvider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { PressHookTheme } from '../../../../../../../config/static/js/theme/mui-theme';

const SocialSignInSection = ({
  accountType = null,
  csrfToken,
  guestCheckout = false,
  mediaProfilePk = null,
  googleSignInURL,
  linkedinSignInURL,
  microsoftSignInURL,
  shopifySignInURL,
  isLogin = false,
}) => {
  const [shopifyExpanded, setShopifyExpanded] = React.useState(false);
  let socialSignInEnabled =
    !!linkedinSignInURL ||
    !!googleSignInURL ||
    !!microsoftSignInURL ||
    !!shopifySignInURL;
  const headerText = isLogin ? 'Log In With:' : 'Sign Up With:';
  const isDisabled = !isLogin && !accountType;
  const handleShopifyButtonClick = () => {
    setShopifyExpanded(!shopifyExpanded);
  };

  return (
    socialSignInEnabled && (
      <ThemeProvider theme={PressHookTheme}>
        <div className="registration-info-row social-signin__subtitle">
          <hr className="social-signin__subtitle--bar" />
          <p className="social-signin__subtitle--text">{headerText}</p>
          <hr className="social-signin__subtitle--bar" />
        </div>
        <Grid
          container
          mb={2}
          spacing={2}
          className={isDisabled ? 'social-signin--disabled' : ''}
        >
          {googleSignInURL && (
            <Grid item xs={12} sm={6}>
              <SocialSignInButton
                signInUrl={googleSignInURL}
                accountType={accountType}
                csrfToken={csrfToken}
                guestCheckout={guestCheckout}
                mediaProfilePk={mediaProfilePk}
                imgSrc={`${staticUrl}/images/svg/logos_google-icon.svg`}
                imgAlt="google-logo"
                buttonText="Google"
              />
            </Grid>
          )}
          {microsoftSignInURL && (
            <Grid item xs={12} sm={6}>
              <SocialSignInButton
                signInUrl={microsoftSignInURL}
                accountType={accountType}
                csrfToken={csrfToken}
                guestCheckout={guestCheckout}
                mediaProfilePk={mediaProfilePk}
                imgSrc={`${staticUrl}/images/svg/logos_microsoft-icon.svg`}
                imgAlt="microsoft-logo"
                buttonText="Microsoft"
              />
            </Grid>
          )}
          {linkedinSignInURL && (
            <Grid item xs={12} sm={6}>
              <SocialSignInButton
                signInUrl={linkedinSignInURL}
                accountType={accountType}
                csrfToken={csrfToken}
                guestCheckout={guestCheckout}
                mediaProfilePk={mediaProfilePk}
                imgSrc={`${staticUrl}/images/svg/logos_linkedin-icon.svg`}
                imgAlt="linkedin-logo"
                buttonText="Linkedin"
              />
            </Grid>
          )}
          {shopifySignInURL && (
            <Grid item xs={12} sm={6}>
              <Button
                variant={shopifyExpanded ? 'contained' : 'outlined'}
                color="black"
                onClick={handleShopifyButtonClick}
                endIcon={
                  shopifyExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />
                }
                startIcon={
                  <img
                    src={`${staticUrl}/images/svg/logos_shopify-icon.svg`}
                    alt="shopify-logo"
                    style={{ width: 24, height: 24 }}
                  />
                }
                fullWidth
              >
                Shopify
              </Button>
            </Grid>
          )}
        </Grid>
        {shopifySignInURL && (
          <Collapse in={shopifyExpanded} timeout="auto" unmountOnExit>
            <ShopifySignInForm
              signInUrl={shopifySignInURL}
              accountType={accountType}
              csrfToken={csrfToken}
              guestCheckout={guestCheckout}
              mediaProfilePk={mediaProfilePk}
            />
          </Collapse>
        )}
        <div className="registration-info-row social-signin__subtitle">
          <hr className="social-signin__subtitle--bar" />
          <p className="social-signin__subtitle--text">
            or continue with email
          </p>
          <hr className="social-signin__subtitle--bar" />
        </div>
      </ThemeProvider>
    )
  );
};

export default SocialSignInSection;
