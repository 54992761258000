import React from 'react';
import PropTypes from 'prop-types';
import { animated, useTransition, config } from '@react-spring/web';

/*
    React Component for displaying a list of tags.

    -------------

    Props:
    tags: array of tags to draw
    deleteHandler: function that takes a tag as an argument, if passed, an X will be
        drawn next to each tag and this function will be called on click
    children: child elements to draw (used to draw search input box)
*/

function TagList({ tags, deleteHandler = null, children }) {
  const transitions = useTransition(tags, {
    from: {
      opacity: 0,
      transform: 'translateY(-100%) rotateX(-45deg)',
      maxWidth: '0px',
      marginRight: '0px',
      padding: '0px',
    },
    enter: {
      opacity: 1,
      transform: 'translateY(0%) rotateX(0deg)',
      maxWidth: '300px',
      marginRight: '4px',
      padding: '5px',
    },
    leave: {
      opacity: 0,
      transform: 'translateY(100%) rotateX(45deg)',
      maxWidth: '0px',
      marginRight: '0px',
      padding: '0px',
    },
    config: config.nobounce,
  });

  const useDistinctName = (t1) => tags.filter((t2) => t1.name === t2.name).length > 1;

  return (
    <div className="tag-list__container">
      {transitions((styles, tag) => (
        <animated.div
          style={styles}
          key={tag?.slug}
          className="tag-list__tag-pill"
        >
          <span data-testid="tagListTag" className="tag-list__tag-text">
            {useDistinctName(tag) ? tag?.distinct_name : tag?.name}
          </span>
          {deleteHandler && (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <span
              onClick={(_) => deleteHandler(tag)}
              data-testid="tagListDeleteBtn"
              className="tag-list__delete-btn"
            />
          )}
        </animated.div>
      ))}
      {children}
    </div>
  );
}

TagList.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({
    slug: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  deleteHandler: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default TagList;
