document.addEventListener('DOMContentLoaded', () => {
  // For Home Hero Display Features
  const homeHeroButtons = Array.from(
    document.querySelectorAll('.home-hero-list__item')
  );
  const homeHeroImages = Array.from(
    document.querySelectorAll('.home-hero__image')
  );

  let currentIndex = 0;

  function homeHeroImageShow(newIndex) {
    homeHeroImages[currentIndex].classList.remove('home-hero__image--active');
    homeHeroButtons[currentIndex].classList.remove(
      'home-hero-list__item--active'
    );

    currentIndex = newIndex;
    homeHeroImages[currentIndex].classList.add('home-hero__image--active');
    homeHeroButtons[currentIndex].classList.add('home-hero-list__item--active');

    const video = homeHeroImages[currentIndex].querySelector('video');
    video.currentTime = 0;
    video.play();
  }

  function handleButtonClick(index) {
    if (
      homeHeroButtons[index].classList.contains('home-hero-list__item--active')
    ) {
      return;
    }
    homeHeroImageShow(index);
  }

  homeHeroButtons.forEach((button, index) =>
    button.addEventListener('click', () => handleButtonClick(index))
  );

  homeHeroImages.forEach((image, index) => {
    const video = image.querySelector('video');
    video.addEventListener('ended', () => {
      const nextIndex = (index + 1) % homeHeroImages.length;
      homeHeroImageShow(nextIndex);
    });
  });

  // Initialize first element if there is one
  if (homeHeroImages.length === 0) return;
  const firstVideo = homeHeroImages[0]?.querySelector('video');
  if (firstVideo) {
    firstVideo.muted = true;
    firstVideo.play();
  }
});
