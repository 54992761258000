import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import getCookie from '../../../../../../../config/static/js/utils/getCookie';
import {
  createSubscriptionWithPaymentMethod,
  getActivePromoForProduct,
  getPricingPlanDetails,
  getTotalUnitAmountFromQuantity,
} from '../../utils';

/**
 * This component renders the form for the user to input their payment information using Stripe.
 *
 * @param {Object} props
 * @param {boolean} props.userIsExistingCustomer whether the user is already a paying customer
 * @param {string} props.selectedPrice the selected price id set in the previous step
 * @param {number} props.trialLength the trial length set in the previous step
 * @param {function} props.setTrialLength react state function to set the trial length
 * @param {string} props.initialPromoCode the promo code from the url search params
 * @param {string} props.promoCode the promo code after handle (initialPromoCode or getValidPromoCode)
 * @param {function} props.setPromoCode react state function to set the promo code
 * @param {number} props.rawPriceTotal the total price without discounts
 * @param {function} props.setRawPriceTotal react state function to set the total price without discounts
 * @param {number} props.rawTotalDue the total due today
 * @param {number} props.rawTotalWithDiscount the total due after discounts
 * @param {Object} props.couponData the coupon data from the promo code (stripe object)
 * @param {function} props.setCouponData react state function to set the coupon data
 * @param {function} props.setSubscriptionId react state function to set the subscription id
 * @param {number} props.organizationPk the organization pk
 * @param {boolean} props.sixMonthCommitment whether the user should commit 6 months
 * @param {boolean} props.agreedToCommitment whether the user agreed to the commitment
 * @param {function} props.setAgreedToCommitment react state function to set the agreed to commitment
 * @param {Object} props.metadata the metadata object to send to the subscription creation
 * @returns {React.Component} The StripeCheckoutForm component
 */
function StripeCheckoutForm({
  userIsExistingCustomer,
  selectedPrice,
  trialLength,
  setTrialLength,
  setRegistrationStage,
  nextRegistrationStage,
  initialPromoCode = '',
  promoCode,
  setPromoCode,
  rawPriceTotal = '',
  setRawPriceTotal,
  rawTotalDue,
  rawTotalWithDiscount,
  couponData,
  setCouponData,
  setSubscriptionId,
  organizationPk,
  sixMonthCommitment = false,
  agreedToCommitment = true,
  setAgreedToCommitment = () => {},
  metadata = {},
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [businessName, setBusinessName] = useState('');
  const [businessNameError, _setBusinessNameError] = useState('');
  const [pricingPlanDetails, setPricingPlanDetails] = useState(null);
  const [annualSelected, setAnnualSelected] = useState(false);

  // Quantities & unit amounts
  const [quantity, setQuantity] = useState(1);
  const [quantityError, setQuantityError] = useState(null);
  const [unitAmount, setUnitAmount] = useState(null);
  const [minimumQuantity, setMinimumQuantity] = useState(null);

  const [error, setError] = useState(null);
  const [promoCodeError, setPromoCodeError] = useState('');
  const [processing, setProcessing] = useState(false);

  // Whenever the selected price changes, get the pricing plan details
  useEffect(() => {
    // Since the price is already selected, there is no need to hide plans not listed (is_plan_selection)
    getPricingPlanDetails(selectedPrice, false).then((results) => {
      if (results) {
        setPricingPlanDetails(results);
        if (selectedPrice === results?.yearly_price?.id) {
          setAnnualSelected(true);
          setUnitAmount(results.yearly_unit_amount);
          setMinimumQuantity(results.minimum_quantity);
        } else {
          setAnnualSelected(false);
          setUnitAmount(results.monthly_unit_amount);
          setMinimumQuantity(results.minimum_quantity);
        }
      }
    });
  }, [selectedPrice]);

  // Updates sums from any change in quantity, unit amount, or pricing plan details
  useEffect(() => {
    if (pricingPlanDetails) {
      if (minimumQuantity && quantity < minimumQuantity) {
        setQuantityError(`Quantity must be at least ${minimumQuantity}.`);
      } else if (quantity < 1) {
        setQuantityError('Quantity must be at least 1.');
      } else {
        setQuantityError(null);
        setRawPriceTotal(
          getTotalUnitAmountFromQuantity(
            pricingPlanDetails,
            quantity,
            annualSelected
          )
        );
      }
    }
  }, [unitAmount, quantity, pricingPlanDetails, annualSelected]);

  /**
   * Validates the inserted promo code
   * @param {boolean} preSubmit means that the function is being called before the form is submitted
   * @returns
   */
  async function validatePromoCode(preSubmit = false) {
    if (promoCode) {
      return (
        fetch(`/api/stripe/promo-codes/validate/`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie('csrftoken'),
          },
          body: JSON.stringify({
            code: promoCode,
          }),
        })
          // Return the response as JSON
          .then((res) => res.json())
          .then((data) => {
            // Submiting the form
            if (!preSubmit) return data;

            // If the data is not valid, show the error message and prevent the form submission
            if (!data.is_valid) {
              setPromoCodeError('Promotion is not valid.');
              setCouponData({});
              return null;
            }

            // If the promo code has a minimum amount and the selected plan does not meet it, show the error message
            if (
              data.restrictions?.minimum_amount !== null &&
              data.restrictions?.minimum_amount > rawPriceTotal
            ) {
              setPromoCodeError(
                'Your selected plan does not meet the required minimum amount for this promotion.'
              );
              setCouponData({});
              return null;
            }

            // All good, set the promo code data and the error message to empty
            setPromoCodeError('');
            setCouponData(data.coupon_data);
            return null;
          })
      );
    }

    // No promo code, so no need to validate
    setCouponData({});
    setPromoCodeError('');
    return null;
  }

  useEffect(() => {
    /**
     * every time promoCode changes (or if the component already initiates with a value for it)
     * will run the validation function and update the due today, so the field onBlur will no
     * longer be needed. The con is that runs on every update, that's why it's checking for a
     * minimum of three chars before runs
     */
    if (promoCode && promoCode.length > 3) validatePromoCode(true);
    if (promoCode.length === 0) {
      setPromoCodeError('');
      setCouponData({});
    }
  }, [promoCode]);

  // Whenever we get the pricing plan details, check for active promos
  useEffect(() => {
    if (pricingPlanDetails) {
      // initialPromoCode overrides the product default/url promo, so no need to get it
      // also, if already a paying customer, we won't give default/url promos to them.
      if (!initialPromoCode && !userIsExistingCustomer) {
        const activePromo = getActivePromoForProduct(
          pricingPlanDetails,
          annualSelected
        );

        // If there is an active promo, we can assume the usage of its promo code and trial days
        if (activePromo) {
          if (activePromo.trial_days && (trialLength <= 0 || !trialLength)) {
            setTrialLength(activePromo.trial_days);
          }
          if (activePromo.promo_code && !promoCode) {
            setPromoCode(activePromo.promo_code);
          }
        }
      }

      if (
        pricingPlanDetails.is_tiered &&
        pricingPlanDetails.minimum_quantity &&
        quantity < pricingPlanDetails.minimum_quantity
      ) {
        setQuantity(pricingPlanDetails.minimum_quantity);
      }
    }
  }, [pricingPlanDetails]);

  // Custom styling for Stripe fields
  const fieldStyling = {
    style: {
      base: {
        color: '#262121',
        fontFamily:
          '"Chiswick Grotesque Web", "Helvetica Neue", Arial, sans-serif',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '3',
        padding: '10px 10px 10px 14px',
        borderRadius: '4px',
      },
      invalid: {
        color: '#D2281A',
      },
    },
    placeholder: '',
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);
    if (!stripe || !elements) {
      setProcessing(false);
      return;
    }

    let promoCodeData = {};

    if (promoCode) {
      promoCodeData = await validatePromoCode();

      if (!promoCodeData.is_valid) {
        setPromoCodeError('Promotion is not valid.');
        setProcessing(false);
        return;
      }
    }

    // Get the card info and create a Payment Method
    const card = elements.getElement(CardElement);
    const { paymentMethod, stripeError } = await stripe.createPaymentMethod({
      type: 'card',
      card,
    });

    // Check for errors
    if (stripeError) {
      setError(stripeError.message);
      setProcessing(false);
    } else {
      // Process the subscription
      const subscriptionResponse = await createSubscriptionWithPaymentMethod(
        selectedPrice,
        trialLength,
        quantity,
        paymentMethod.id,
        businessName,
        promoCodeData.promo_code_id,
        organizationPk,
        null,
        null,
        null,
        null,
        metadata
      );
      // Display an error is there is one
      if (subscriptionResponse.error) {
        setError(subscriptionResponse.error);
        setProcessing(false);

        // Otherwise track a conversion and move to Finish Stage
      } else {
        setSubscriptionId(subscriptionResponse.id);
        // Card Entered Event
        if (window.analytics) {
          window.analytics.track('Card Entered', {
            subscriptionId: subscriptionResponse.id,
            total: subscriptionResponse.items.data[0].price.unit_amount / 100,
            coupon: promoCode,
            products: subscriptionResponse.items.data,
            currency: subscriptionResponse.currency,
          });

          // Order Completed Event
          window.analytics.track('Order Completed', {
            orderId: subscriptionResponse.id,
            total: subscriptionResponse.items.data[0].price.unit_amount / 100,
            coupon: promoCode,
            products: subscriptionResponse.items.data,
            currency: subscriptionResponse.currency,
          });
        }

        // First Promoter Order Completed Event for Customer Referrals
        if (window.fpr)
          window.fpr('referral', { uid: subscriptionResponse.customer });

        // Go to final stage
        setRegistrationStage(nextRegistrationStage);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {error && (
        <p className="registration-info-input-element__error-text">{error}</p>
      )}
      <div className="registration-info-section-title">
        Business Information:
      </div>
      <div className="registration-info-row">
        <div className="registration-info-row__column">
          <label
            className="registration-info-input-element__title"
            htmlFor="businessName"
          >
            Business Name
          </label>
          <input
            id="businessName"
            className="registration-info-input-element"
            type="text"
            placeholder="Business Name"
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
            required
          />
          <div className="registration-info-input-element__tip">
            👉 Tip: This is the name that will appear on invoices.
          </div>
          {businessNameError && (
            <div className="registration-info-input-element__error-text">
              {businessNameError}
            </div>
          )}
        </div>
      </div>
      <div className="registration-info-row registration-info-row">
        <div className="registration-info-row__column">
          <div className="registration-info-section-title">
            Billing Information:
          </div>
          <label
            className="registration-info-input-element__title"
            htmlFor="card-element"
          >
            Card Details
          </label>
          <CardElement
            id="card-element"
            className="registration-info-input-element registration-info-input-element--stripe"
            options={fieldStyling}
          />
        </div>
      </div>
      {pricingPlanDetails?.is_tiered && (
        <div className="registration-info-row registration-info-row--mt">
          <div className="registration-info-row__column">
            <label
              className="registration-info-input-element__title"
              htmlFor="quantity"
            >
              Number of Brands
            </label>
            <input
              id="quantity"
              className="registration-info-input-element"
              type="text"
              placeholder="1"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
            />
            {quantityError && (
              <div className="registration-info-input-element__error-text">
                {quantityError}
              </div>
            )}
          </div>
        </div>
      )}
      <div className="registration-info-row registration-info-row--mt">
        <div className="registration-info-row__column">
          <label
            className="registration-info-input-element__title"
            htmlFor="promoCode"
          >
            Promo Code
          </label>
          <input
            id="promoCode"
            className="registration-info-input-element"
            type="text"
            placeholder="Promo Code"
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
            data-testid="promoCodeInput"
          />
          {promoCodeError && (
            <div className="registration-info-input-element__error-text">
              {promoCodeError}
            </div>
          )}
        </div>
      </div>
      <div className="promo-code-details" data-testid="promoCodeDetails">
        <p className="promo-code-details__header">Payment details:</p>
        {trialLength > 0 && (
          <div className="promo-code-details__row">
            <p className="promo-code-details-text">1st {trialLength} days</p>
            <p className="promo-code-details-text promo-code-details-text--light">
              FREE
            </p>
          </div>
        )}
        <div className="promo-code-details__row">
          <p className="promo-code-details-text">Due today:</p>
          <p className="promo-code-details-text promo-code-details-text--bold promo-code-details-text--red">
            ${(rawTotalDue / 100).toFixed(2)}
          </p>
        </div>
        <div className="promo-code-details__row promo-code-details__row--border-bottom" />
        {couponData.duration_in_months && (
          <div className="promo-code-details__row">
            <p className="promo-code-details-text">
              Next {couponData.duration_in_months} months:
            </p>
            <p className="promo-code-details-text promo-code-details-text--bold">
              ${(rawTotalWithDiscount / 100).toFixed(2)}/mo
            </p>
          </div>
        )}
        {couponData.duration === 'once' && !couponData.duration_in_months && (
          <div className="promo-code-details__row">
            <p className="promo-code-details-text">
              First {couponData.duration_in_months}{' '}
              {annualSelected ? 'year' : 'month'}:
            </p>
            <p className="promo-code-details-text promo-code-details-text--bold">
              ${(rawTotalWithDiscount / 100).toFixed(2)}/
              {annualSelected ? 'yr' : 'mo'}
            </p>
          </div>
        )}
        {couponData.duration && (
          <div className="promo-code-details__row">
            <p className="promo-code-details-text">
              {couponData.duration === 'forever'
                ? 'Discounted price:'
                : 'After promo:'}
            </p>
            <p className="promo-code-details-text promo-code-details-text--bold">
              {couponData.duration === 'forever' && (
                <>
                  ${(rawTotalWithDiscount / 100).toFixed(2)}/
                  {annualSelected ? 'yr' : 'mo'}
                </>
              )}

              {couponData.duration !== 'forever' && (
                <>
                  ${(rawPriceTotal / 100).toFixed(2)}/
                  {annualSelected ? 'yr' : 'mo'}
                </>
              )}
            </p>
          </div>
        )}
        {sixMonthCommitment && (
          <div className="promo-code-details__row">
            <FormControlLabel
              control={
                <Checkbox
                  checked={agreedToCommitment}
                  onChange={(e) => setAgreedToCommitment(e.target.checked)}
                  label=""
                  inputProps={{ 'data-testid': 'sixMonthCommitmentCheckbox' }}
                />
              }
              label="I agree to Press Hook’s 6-month minimum term"
              classes={{ label: 'promo-code-details-commitment' }}
            />
          </div>
        )}
      </div>
      <button
        className="multi-step-button"
        disabled={!stripe || processing || !agreedToCommitment}
        type="submit"
      >
        {processing
          ? 'Submitting...'
          : agreedToCommitment
          ? 'Submit'
          : 'Please agree to the commitment'}
      </button>
    </form>
  );
}

StripeCheckoutForm.propTypes = {
  userIsExistingCustomer: PropTypes.bool.isRequired,
  selectedPrice: PropTypes.string.isRequired,
  trialLength: PropTypes.number.isRequired,
  setTrialLength: PropTypes.func.isRequired,
  setRegistrationStage: PropTypes.func.isRequired,
  nextRegistrationStage: PropTypes.number.isRequired,
  initialPromoCode: PropTypes.string,
  promoCode: PropTypes.string.isRequired,
  setPromoCode: PropTypes.func.isRequired,
  rawPriceTotal: PropTypes.number,
  setRawPriceTotal: PropTypes.func.isRequired,
  rawTotalDue: PropTypes.number.isRequired,
  rawTotalWithDiscount: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  couponData: PropTypes.object.isRequired,
  setCouponData: PropTypes.func.isRequired,
  setSubscriptionId: PropTypes.func.isRequired,
  organizationPk: PropTypes.number.isRequired,
  sixMonthCommitment: PropTypes.bool,
  agreedToCommitment: PropTypes.bool,
  setAgreedToCommitment: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  metadata: PropTypes.object,
};

export default StripeCheckoutForm;
