import EmblaCarousel from 'embla-carousel';
import Autoplay from 'embla-carousel-autoplay';

const autoplayOptions = {
  delay: 6000, // 6s delay
  stopOnMouseEnter: true,
  stopOnFocusIn: true,
};

function updateSlideIconsAndImage(emblaInstance, pageType) {
  const slideIndex = emblaInstance.selectedScrollSnap();
  const previousIcon = document.querySelector(
    `.testimonial-slide-icon-container__icon--${pageType}.testimonial-slide-icon-container__icon--selected`
  );
  const selectedIcon = document.getElementById(`slide-icon-${slideIndex}--${pageType}`);

  // Remove 'selected' class from previously selected icon
  if (previousIcon) {
    previousIcon.classList.remove('testimonial-slide-icon-container__icon--selected');
  }

  // Add 'selected' class to current icon
  if (selectedIcon) {
    selectedIcon.classList.add('testimonial-slide-icon-container__icon--selected');
  }

  // Update the testimonial image
  const currentSlide = emblaInstance.slideNodes()[slideIndex];
  const imgUrl = currentSlide.dataset.imageUrl; // Ensure your slides have a data-image-url attribute
  const imageContainer = document.getElementById(`testimonial-image--${pageType}`);

  if (imageContainer && imgUrl) {
    imageContainer.style.backgroundImage = `url(${imgUrl})`;
  }
}


function changeSlide(
  emblaInstance,
  scrollFunc,
  imgTargetEnabled = false,
  slideId = null,
  pageType = ''
) {
  if (slideId !== null) {
    scrollFunc(slideId, false);
  } else {
    scrollFunc(false);
  }

  if (imgTargetEnabled) {
    const slideIndex = emblaInstance.selectedScrollSnap();
    const previousIcon = document.querySelector(
      `.testimonial-slide-icon-container__icon--${pageType}.testimonial-slide-icon-container__icon--selected`
    );
    const selectedIcon = document.getElementById(
      `slide-icon-${slideIndex}--${pageType}`
    );
    previousIcon.className = 'testimonial-slide-icon-container__icon';
    // eslint-disable-next-line max-len
    selectedIcon.className = `testimonial-slide-icon-container__icon testimonial-slide-icon-container__icon--${pageType} testimonial-slide-icon-container__icon--selected`;
    const currentSlide = emblaInstance.slideNodes()[slideIndex];
    const imgUrl = currentSlide.dataset.imageUrl;
    const imageContainer = document.getElementById(
      `testimonial-image--${pageType}`
    );

    imageContainer.style.backgroundImage = `url(${imgUrl})`;
  }
}

function renderSlideIcons(
  emblaInstance,
  className,
  selectedClassName,
  slides,
  parentContainer,
  pageType
) {
  for (let i = 0; i < slides.length; i += 1) {
    const icon = document.createElement('div');
    icon.className = i === 0 ? selectedClassName : className;
    icon.id = `slide-icon-${i}--${pageType}`;
    icon.setAttribute('data-slide-number', i);
    parentContainer.appendChild(icon);

    icon.addEventListener('click', () => {
      changeSlide(emblaInstance, emblaInstance.scrollTo, true, i, pageType);
    });
  }
}

/* eslint no-param-reassign: ["error", { "props": false }] */
function setArrowVisibility(embla, prevButton, nextButton) {
  const show = embla.slidesNotInView().length !== 0;

  if (show) {
    prevButton.style.display = 'block';
    nextButton.style.display = 'block';
  } else {
    prevButton.style.display = 'none';
    nextButton.style.display = 'none';
  }
}

function setInitialTestimonialImage(pageType) {
  const imageContainer = document.getElementById(
    `testimonial-image--${pageType}`
  );

  if (imageContainer) {
    const imgUrl = imageContainer.dataset.initialImageUrl;
    imageContainer.style.backgroundImage = `url(${imgUrl})`;
  }
}

function classChangeCallback(mutationList, embla) {
  mutationList.forEach((mutation) => {
    if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
      embla.reInit();
    }
  });
}

document.addEventListener('DOMContentLoaded', () => {
  const pageTypes = ['Brands', 'Media', 'Agencies'];

  const options = {
    loop: true,
    align: 'start',
    skipSnaps: false,
  };

  const brandSuccessContainer = document.getElementById('brandSuccessCases');
  const brandTestimonialsContainer =
    document.getElementById('brandTestimonials');

  const mediaSuccessContainer = document.getElementById('mediaSuccessCases');
  const mediaTestimonialsContainer =
    document.getElementById('mediaTestimonials');

  const agencySuccessContainer = document.getElementById('agencySuccessCases');
  const agencyTestimonialsContainer =
    document.getElementById('agencyTestimonials');

  const mutationOptions = {
    attributes: true,
  };

  pageTypes.forEach((pageType) => {
    const successCaseEmblaNode = document.querySelector(
      `.success-case-embla--${pageType}`
    );
    const successCases = document.querySelectorAll(
      `.success-case-item--${pageType}`
    );
    const successCaseNextButton = document.querySelector(
      `.success-case-slider__next--${pageType}`
    );
    const successCasePrevButton = document.querySelector(
      `.success-case-slider__prev--${pageType}`
    );

    if (successCaseEmblaNode && successCases.length > 0) {
      const plugins = [Autoplay(autoplayOptions)];
      const successCaseEmbla = EmblaCarousel(
        successCaseEmblaNode,
        options,
        plugins
      );

      setArrowVisibility(
        successCaseEmbla,
        successCasePrevButton,
        successCaseNextButton
      );

      successCaseNextButton.addEventListener('click', () => {
        changeSlide(successCaseEmbla, successCaseEmbla.scrollNext);
      });
      successCasePrevButton.addEventListener('click', () => {
        changeSlide(successCaseEmbla, successCaseEmbla.scrollPrev);
      });
      successCaseEmbla.on('resize', () => {
        setArrowVisibility(
          successCaseEmbla,
          successCasePrevButton,
          successCaseNextButton
        );
      });

      const observer = new MutationObserver((mutationList) => {
        classChangeCallback(mutationList, successCaseEmbla);
      });
      if (pageType === 'Brands') {
        observer.observe(brandSuccessContainer, mutationOptions);
      } else if (pageType === 'Media') {
        observer.observe(mediaSuccessContainer, mutationOptions);
      } else if (pageType === 'Agencies') {
        observer.observe(agencySuccessContainer, mutationOptions);
      }
    }

    setInitialTestimonialImage(pageType);
    const testimonialEmblaNode = document.querySelector(
      `.testimonial-embla--${pageType}`
    );
    const testimonials = document.querySelectorAll(`.testimonial--${pageType}`);
    const testimonialNextButton = document.querySelector(
      `.testimonial-slider__next--${pageType}`
    );
    const testimonialPrevButton = document.querySelector(
      `.testimonial-slider__prev--${pageType}`
    );
    const testimonialSlideIconContainer = document.querySelector(
      `.testimonial-slide-icon-container--${pageType}`
    );

    if (testimonialEmblaNode && testimonials.length > 0) {
      const plugins = [Autoplay(autoplayOptions)];
      const testimonialEmbla = EmblaCarousel(
        testimonialEmblaNode,
        options,
        plugins
      );
      const testimonialSlides = testimonialEmbla.slideNodes();

      renderSlideIcons(
        testimonialEmbla,
        `testimonial-slide-icon-container__icon testimonial-slide-icon-container__icon--${pageType}`,
        // eslint-disable-next-line max-len
        `testimonial-slide-icon-container__icon testimonial-slide-icon-container__icon--${pageType} testimonial-slide-icon-container__icon--selected`,
        testimonialSlides,
        testimonialSlideIconContainer,
        pageType
      );

      setArrowVisibility(
        testimonialEmbla,
        testimonialPrevButton,
        testimonialNextButton
      );

      testimonialNextButton.addEventListener('click', () => {
        changeSlide(
          testimonialEmbla,
          testimonialEmbla.scrollNext,
          true,
          null,
          pageType
        );
      });
      testimonialPrevButton.addEventListener('click', () => {
        changeSlide(
          testimonialEmbla,
          testimonialEmbla.scrollPrev,
          true,
          null,
          pageType
        );
      });
      testimonialEmbla.on('resize', () => {
        setArrowVisibility(
          testimonialEmbla,
          testimonialPrevButton,
          testimonialNextButton
        );
      });
      testimonialEmbla.on('select', () => {
        updateSlideIconsAndImage(testimonialEmbla, pageType);
      });

      const observer = new MutationObserver((mutationList) => {
        classChangeCallback(mutationList, testimonialEmbla);
      });

      if (pageType === 'Brands') {
        observer.observe(brandTestimonialsContainer, mutationOptions);
      } else if (pageType === 'Media') {
        observer.observe(mediaTestimonialsContainer, mutationOptions);
      } else if (pageType === 'Agencies') {
        observer.observe(agencyTestimonialsContainer, mutationOptions);
      }
    }
  });
});
