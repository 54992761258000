/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, Checkbox, Button } from '@mui/material';
import { PressHookTheme } from '../../../../../../../config/static/js/theme/mui-theme';
import getCookie from '../../../../../../../config/static/js/utils/getCookie';
import logError from '../../../../../../../config/static/js/utils/logError';
import SocialSignInSection from '../SocialSignIn/SocialSignInSection';

function AccountTypeSelectForm({
  setRegistrationStage = () => {},
  nextRegistrationStage = undefined,
  title = null,
  subtitle = null,
  accountType = '',
  setAccountType = () => {},
  selectableAccountType = true,
  setUserIsAuthenticated,
  initialEmail = undefined,
  buttonText = 'Continue',
  linkedinSignInURL,
  googleSignInURL,
  microsoftSignInURL,
  shopifySignInURL,
  csrfToken,
  isAmex = false,
  invitationId = null,
  guestCheckout = false,
  obscuredEmail = null,
  mediaProfilePk = null,
}) {
  const [email, setEmail] = useState(initialEmail || undefined);
  const [emailError, setEmailError] = useState([]);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState([]);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [agreedToTermsError, setAgreedToTermsError] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [genericError, setGenericError] = useState([]);

  const errorHandlingMap = {
    email: setEmailError,
    password: setPasswordError,
    agreed_to_terms: setAgreedToTermsError,
    error: setGenericError,
  };

  function validatePasswords() {
    if (password !== confirmPassword) {
      setPasswordError(['Passwords do not match.']);
      return false;
    }
    setPasswordError([]);
    return true;
  }

  function validateAgreedToTerms() {
    if (!agreedToTerms) {
      setAgreedToTermsError(['You must agree to the Terms & Conditions.']);
      return false;
    }
    setAgreedToTermsError([]);
    return true;
  }

  const handleSubmit = async (e) => {
    setProcessing(true);
    e.preventDefault();
    if (validatePasswords() && validateAgreedToTerms()) {
      try {
        const res = await fetch(
          `/api/users/${
            accountType === 'agency' ? 'brand' : accountType
          }/sign-up/`,
          {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-CSRFToken': getCookie('csrftoken'),
            },
            body: JSON.stringify({
              email,
              password,
              confirm_password: confirmPassword,
              agreed_to_terms: agreedToTerms,
              is_agency: accountType === 'agency',
              invitation_guid: invitationId,
              media_profile_pk: mediaProfilePk,
            }),
          }
        );
        if (res.ok) {
          setRegistrationStage(nextRegistrationStage);
          setUserIsAuthenticated(true);
        } else {
          res.json().then((errDict) => {
            setProcessing(false);

            const fields = Object.keys(errDict);
            fields.forEach((field) => {
              const formattedError = errDict[field][0].replace(/'/g, '"');
              let error;
              try {
                error = JSON.parse(formattedError);
              } catch {
                error = formattedError;
              }
              if (!Array.isArray(error)) {
                errorHandlingMap[field]([error]);
              } else {
                errorHandlingMap[field](error);
              }
            });
          });
        }
      } catch (err) {
        logError(err);
        setProcessing(false);
      }
    } else {
      setProcessing(false);
    }
  };

  return (
    <ThemeProvider theme={PressHookTheme}>
      {isAmex && (
        <img
          src={`${staticUrl}/images/sign_up/amex-logo.svg`}
          alt="amex-logo"
          className="amex-top-logo"
        />
      )}
      {title && <h3 className="multi-step-title">{title}</h3>}
      {subtitle && <p className="multi-step-subtitle">{subtitle}</p>}
      {selectableAccountType && (
        <div className="registration-info-row">
          <div className="registration-info-row__column">
            <label className="registration-info-input-element__title" htmlFor="accountTypeSelector">
              Account Type
            </label>
            <select
              id="accountTypeSelector"
              defaultValue={accountType}
              className="registration-info-input-element"
              onChange={(e) => setAccountType(e.target.value)}
            >
              <option>Choose an account type...</option>
              <option value="brand">Brand</option>
              <option value="media">Media</option>
              <option value="agency">Agency</option>
              <option value="expert">Expert</option>
            </select>
            <div className="registration-info-input-element__error-text" />
          </div>
        </div>
      )}
      <SocialSignInSection
        accountType={accountType}
        csrfToken={csrfToken}
        guestCheckout={guestCheckout}
        mediaProfilePk={mediaProfilePk}
        googleSignInURL={googleSignInURL}
        linkedinSignInURL={linkedinSignInURL}
        microsoftSignInURL={microsoftSignInURL}
        shopifySignInURL={shopifySignInURL}
      />
      <form onSubmit={handleSubmit}>
        <input type="hidden" name="account_type" value={accountType} />
        <div
          className={
            !accountType
              ? 'registration-info-row registration-info-row--disabled'
              : 'registration-info-row'
          }
        >
          <div className="registration-info-row__column">
            <label className="registration-info-input-element__title" htmlFor="email-input">
              Email
            </label>
            <input
              id="email-input"
              className="registration-info-input-element"
              type="email"
              autoComplete="email"
              placeholder="company@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={!accountType}
              data-testid="email-input"
            />
            {obscuredEmail && emailError.length === 0 && (
              <div className="registration-info-input-element__info-text">
                You are claiming a profile owned by <b>{obscuredEmail}</b>. Be
                sure to use the same e-mail to sign-up. If you need to change
                it, please contact support.
              </div>
            )}
            {emailError.map((errorString, key) => (
              <div
                className="registration-info-input-element__error-text"
                key={key}
              >
                {errorString}
              </div>
            ))}
          </div>
        </div>
        <div
          className={
            !accountType
              ? 'registration-info-row registration-info-row--disabled'
              : 'registration-info-row'
          }
        >
          <div className="registration-info-row__column">
            <label className="registration-info-input-element__title" htmlFor="password-input">
              Password
            </label>
            <input
              id="password-input"
              className="registration-info-input-element"
              type="password"
              autoComplete="new-password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              disabled={!accountType}
              data-testid="passwordInput"
            />
            <div className="registration-info-input-element__error-text" />
          </div>
        </div>
        <div
          className={
            !accountType
              ? 'registration-info-row registration-info-row--disabled'
              : 'registration-info-row'
          }
        >
          <div className="registration-info-row__column">
            <label className="registration-info-input-element__title" htmlFor="confirm-password-input">
              Confirm Password
            </label>
            <input
              id="confirm-password-input"
              className="registration-info-input-element"
              type="password"
              autoComplete="new-password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              disabled={!accountType}
              data-testid="confirmPasswordInput"
            />
            {passwordError.map((errorString, key) => (
              <div
                className="registration-info-input-element__error-text"
                key={key}
              >
                {errorString}
              </div>
            ))}
          </div>
        </div>
        <label
          className={
            !accountType
              ? 'checkbox registration-info-row--mb registration-info-row--disabled'
              : 'checkbox registration-info-row--mb'
          }
        >
          <Checkbox
            checked={agreedToTerms}
            disabled={!accountType}
            onChange={(e) => setAgreedToTerms(e.target.checked)}
            inputProps={{ 'data-testid': 'termsCheckbox' }}
          />
          <span
            className={
              !accountType
                ? 'selection selection--wrap selection--disabled'
                : 'selection selection--wrap'
            }
          >
            I Agree to the{' '}
            <a
              target="_blank"
              href="/terms"
              className="ph-red-link registration-policy-link"
            >
              Terms & Conditions
            </a>{' '}
            and{' '}
            <a
              target="_blank"
              href="/privacy-policy"
              className="ph-red-link registration-policy-link"
            >
              Privacy Policy
            </a>
          </span>
        </label>
        {agreedToTermsError.map((errorString, key) => (
          <div
            className="registration-info-input-element__error-text"
            key={key}
          >
            {errorString}
          </div>
        ))}
        <Button
          className="multi-step-button"
          type="submit"
          disabled={processing || !accountType}
          variant="contained"
          data-testid="continueButton"
        >
          {processing ? 'Submitting...' : buttonText}
        </Button>
        {genericError.map((errorString, key) => (
          <div
            className="registration-info-input-element__error-text"
            key={key}
          >
            {errorString}
          </div>
        ))}
      </form>
    </ThemeProvider>
  );
}

AccountTypeSelectForm.propTypes = {
  setRegistrationStage: PropTypes.func,
  nextRegistrationStage: PropTypes.number,
  accountType: PropTypes.string,
  setAccountType: PropTypes.func,
  selectableAccountType: PropTypes.bool,
  initialEmail: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  setUserIsAuthenticated: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  csrfToken: PropTypes.string.isRequired,
  isAmex: PropTypes.bool,
  invitationId: PropTypes.string,
  linkedinSignInURL: PropTypes.string.isRequired,
  googleSignInURL: PropTypes.string.isRequired,
  microsoftSignInURL: PropTypes.string.isRequired,
  shopifySignInURL: PropTypes.string.isRequired,
  guestCheckout: PropTypes.bool,
  obscuredEmail: PropTypes.string,
  mediaProfilePk: PropTypes.number,
};

export default AccountTypeSelectForm;
