import React from 'react';
import LoadingRectangle from '../../../../../../analytics/static/brand_dashboard/js/LoadingComponents/LoadingRectangle';

function LoadingForm() {
  return (
    <>
      <div className="mulit-step-loader-container" data-testid="loadingFormComponent">
        <LoadingRectangle height={80} />
      </div>
      <div className="mulit-step-loader-container">
        <LoadingRectangle height={40} />
      </div>
      <div className="mulit-step-loader-container">
        <LoadingRectangle height={40} />
      </div>
      <div className="mulit-step-loader-container">
        <LoadingRectangle height={40} />
      </div>
      <div className="mulit-step-loader-container">
        <LoadingRectangle height={40} />
      </div>
    </>
  );
}

export default LoadingForm;