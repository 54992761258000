// thanks, https://stackoverflow.com/questions/6506897/csrf-token-missing-or-incorrect-while-post-parameter-via-ajax-in-django
/* istanbul ignore file */

function getCookie(cookieName) {
  if (document.cookie.length > 0) {
    let cookieStart = document.cookie.indexOf(`${cookieName}=`);
    if (cookieStart !== -1) {
      cookieStart = cookieStart + cookieName.length + 1;
      let cookieEnd = document.cookie.indexOf(';', cookieStart);
      if (cookieEnd === -1) cookieEnd = document.cookie.length;
      return unescape(document.cookie.substring(cookieStart, cookieEnd));
    }
  }
  return '';
}

export default getCookie;
