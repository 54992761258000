export default function pricingHandlers() {
  const select = (selector) => document.querySelector(selector);
  const selectAll = (selector) => document.querySelectorAll(selector);

  const addClass = (element, className) => element.classList.add(className);
  const removeClass = (element, className) =>
    element.classList.remove(className);

  const isChecked = (checkbox) => checkbox && checkbox.checked;

  const stickyPriceArea = select('.sticky-price-area');
  const priceTabsContainer = select('.price-tab__container');
  const priceCheckInput = select('.price-billed-checkbox__input');
  const stickyPriceCheckInput = select('.sticky-price-billed-checkbox__input');
  const billedTexts = selectAll('.price-tab__billed-text');
  const stickyBilledTexts = selectAll('.sticky-price-tab__nav-billed-text');
  const annualPromoDisplayContainers = selectAll(
    '.price-promo-display-container--yearly'
  );
  const monthlyPromoDisplayContainers = selectAll(
    '.price-promo-display-container--monthly'
  );

  const priceTabCard = selectAll('.price-tab__card');
  const stickyPriceTabCard = selectAll('.sticky-price__card');

  const toggleBilledText = (input, billedTextBlocks) => {
    const activeIndex = isChecked(input) ? 1 : 0;
    billedTextBlocks.forEach((billedText, index) => {
      if (index === activeIndex) {
        addClass(billedText, 'price-tab__billed-text--active');
      } else {
        removeClass(billedText, 'price-tab__billed-text--active');
      }
    });
  };

  const toggleStickyBilledText = (input, billedTextBlocks) => {
    const activeIndex = isChecked(input) ? 1 : 0;
    billedTextBlocks.forEach((billedText, index) => {
      if (index === activeIndex) {
        addClass(billedText, 'sticky-price-tab__nav-billed-text--active');
      } else {
        removeClass(billedText, 'sticky-price-tab__nav-billed-text--active');
      }
    });
  };

  const togglePriceChecked = (element, input, elementClass) => {
    if (isChecked(input)) {
      addClass(element, elementClass);
    } else {
      removeClass(element, elementClass);
    }
  };

  const toggleMonthlyYearlyPromo = (input) => {
    if (isChecked(input)) {
      monthlyPromoDisplayContainers.forEach((promoDisplayContainer) => {
        addClass(
          promoDisplayContainer,
          'price-promo-display-container--hidden'
        );
      });
      annualPromoDisplayContainers.forEach((promoDisplayContainer) => {
        removeClass(
          promoDisplayContainer,
          'price-promo-display-container--hidden'
        );
      });
    } else {
      monthlyPromoDisplayContainers.forEach((promoDisplayContainer) => {
        removeClass(
          promoDisplayContainer,
          'price-promo-display-container--hidden'
        );
      });
      annualPromoDisplayContainers.forEach((promoDisplayContainer) => {
        addClass(
          promoDisplayContainer,
          'price-promo-display-container--hidden'
        );
      });
    }
  };

  if (stickyPriceArea) {
    window.addEventListener('scroll', () => {
      const priceTabsEnd =
        priceTabsContainer.offsetTop + priceTabsContainer.offsetHeight;
      if (window.pageYOffset > priceTabsEnd) {
        addClass(stickyPriceArea, 'sticky-price-area--show');
      } else {
        removeClass(stickyPriceArea, 'sticky-price-area--show');
      }
    });
  }

  const handlePriceChange = (
    priceCard,
    priceCardMonth,
    priceCardAnnual,
    checkInput,
    billedTextBlocks,
    stickyBilledTextBlocks
  ) => {
    priceCard.forEach((card) => {
      const eachCardPrice = card.querySelector(priceCardMonth);
      const priceCardAnnualClass = priceCardAnnual.slice(1);

      checkInput.addEventListener('change', () => {
        togglePriceChecked(eachCardPrice, checkInput, priceCardAnnualClass);
        toggleBilledText(checkInput, billedTextBlocks);
        toggleStickyBilledText(stickyPriceCheckInput, stickyBilledTextBlocks);
        toggleMonthlyYearlyPromo(checkInput);
      });

      if (stickyPriceCheckInput) {
        stickyPriceCheckInput.addEventListener('change', () => {
          togglePriceChecked(
            eachCardPrice,
            stickyPriceCheckInput,
            priceCardAnnualClass
          );
          toggleBilledText(checkInput, billedTextBlocks);
          toggleStickyBilledText(stickyPriceCheckInput, stickyBilledTextBlocks);
        });
      }
    });
  };

  handlePriceChange(
    priceTabCard,
    '.price-tab__card-price',
    '.price-tab__card-price--annually',
    priceCheckInput,
    billedTexts,
    stickyBilledTexts
  );

  handlePriceChange(
    stickyPriceTabCard,
    '.sticky-price__card-price',
    '.sticky-price__card-price--annually',
    stickyPriceCheckInput,
    billedTexts,
    stickyBilledTexts
  );
}

document.addEventListener('DOMContentLoaded', () => {
  pricingHandlers();
});
