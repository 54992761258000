import React from 'react';
import { Button, Box } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';

const SocialSignInButton = ({
  signInUrl,
  accountType,
  csrfToken,
  guestCheckout,
  mediaProfilePk,
  imgSrc,
  imgAlt,
  buttonText = '',
  isDisabled,
  onClick,
}) => {
  const separator = signInUrl.includes('?') ? '&' : '?';
  const actionUrl = accountType
    ? `${signInUrl}${separator}account_type=${accountType}`
    : signInUrl;

  return (
    <Box component="form" method="POST" action={actionUrl}>
      <input type="hidden" name="csrfmiddlewaretoken" value={csrfToken} />
      {accountType && (
        <input type="hidden" name="account_type" value={accountType} />
      )}
      {guestCheckout && (
        <input
          type="hidden"
          name="guest_checkout_url"
          value={window.location.pathname}
        />
      )}
      {mediaProfilePk && (
        <input
          type="hidden"
          name="next"
          value={window.location.pathname + window.location.search}
        />
      )}
      <Button
        type="submit"
        variant={buttonText ? 'outlined' : 'contained'}
        color={buttonText ? 'black' : 'primary'}
        size={buttonText ? 'large' : 'medium'}
        disableElevation
        onClick={onClick}
        disabled={isDisabled}
        sx={buttonText ? { width: '100%' } : { minHeight: '46.44px' }}
        startIcon={
          buttonText ? (
            <img src={imgSrc} alt={imgAlt} style={{ width: 24, height: 24 }} />
          ) : (
            <LoginIcon />
          )
        }
      >
        {buttonText}
      </Button>
    </Box>
  );
};

export default SocialSignInButton;
