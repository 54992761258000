import React from 'react';
import PropTypes from 'prop-types';

/**
 * A shimmering rectangle for a loading state
 */
function LoadingRectangle({ width = null, height, maxWidth = null }) {
  return (
    <div
      className="loadable-element__display"
      style={{
        height: `${height}px`,
        width: width ? `${width}px` : '100%',
        maxWidth: maxWidth ? `${maxWidth}px` : 'none',
        borderRadius: '4px',
        overflow: 'hidden',
      }}
    />
  );
}

LoadingRectangle.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number.isRequired,
  maxWidth: PropTypes.number,
};

export default LoadingRectangle;
