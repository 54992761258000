import React from 'react';
import ReactDOM from 'react-dom/client';

import MultiStepRegistrationForm from '../RegistrationForms/MultiStepRegistrationForm';

/**
 * This file is for injecting the AMEX Offer Page into the page.
 */
document.addEventListener('DOMContentLoaded', () => {
  const reactTarget = document.querySelector('#signUpFormTarget');

  if (reactTarget) {
    const {
      defaultAccountType,
      csrfToken,
      hiddenAccountSelector,
      linkedinUrl,
      googleUrl,
      microsoftUrl,
      shopifyUrl,
      guestCheckout,
      mediaProfilePk,
      obscuredEmail,
    } = reactTarget.dataset;
    const hiddenAccountSelectorBool = hiddenAccountSelector !== undefined;
    const guestCheckoutBool = guestCheckout !== undefined;

    const root = ReactDOM.createRoot(reactTarget);
    root.render(
      <MultiStepRegistrationForm
        csrfToken={csrfToken}
        defaultAccountType={defaultAccountType}
        linkedinSignInURL={linkedinUrl}
        microsoftSignInURL={microsoftUrl}
        shopifySignInURL={shopifyUrl}
        googleSignInURL={googleUrl}
        hiddenAccountSelector={hiddenAccountSelectorBool}
        guestCheckout={guestCheckoutBool}
        mediaProfilePk={mediaProfilePk}
        obscuredEmail={obscuredEmail}
      />
    );
  }
});
