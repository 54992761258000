document.addEventListener('DOMContentLoaded', () => {
  const faqItems = document.querySelectorAll('.faq__item');

  faqItems.forEach((item) => {
    const title = item.querySelector('.faq__title');

    title.addEventListener('click', (e) => {
      const faqItem = e.target.parentElement;
      const faqTitle = faqItem.querySelector('.faq__title');
      const faqAnswer = faqItem.querySelector('.faq__answer');

      faqTitle.classList.toggle('faq__title--expanded');
      faqAnswer.classList.toggle('faq__answer--expanded');

      if (faqAnswer.classList.contains('faq__answer--expanded')) {
        faqAnswer.style.maxHeight = `${faqAnswer.scrollHeight}px`;
      } else {
        faqAnswer.style.maxHeight = null;
      }
    });
  });
});
