document.addEventListener('DOMContentLoaded', () => {
  // For Show Features Tab
  const featuresButtons = document.querySelectorAll(
    '.features-press-list__item'
  );
  const featuresImages = document.querySelectorAll(
    '.features-press__images-main'
  );

  function buttonClickHandler(index) {
    const button = featuresButtons[index];
    const isActive = button.classList.contains(
      'features-press-list__item--active'
    );

    if (isActive) {
      return;
    }

    featuresImages.forEach((image) => {
      image.classList.remove('features-press__images-main--active');
    });

    const activeFeaturesButton = document.querySelector(
      '.features-press-list__item.features-press-list__item--active'
    );
    activeFeaturesButton.classList.remove('features-press-list__item--active');
    button.classList.add('features-press-list__item--active');
    featuresImages[index].classList.add('features-press__images-main--active');
  }

  featuresButtons.forEach((button, index) => {
    button.addEventListener('click', () => buttonClickHandler(index));
  });
});
